import {user} from "@/functions/user";

const setPasskeyFeatureFlag = () => {
        const appName = sessionStorage.getItem('appName')

        if (appName.toLowerCase() === 'freetaxusa') {
                user.isPasskeyTurnedOn = JSON.parse(process.env.VUE_APP_PASSKEY_ENABLED_FREETAXUSA)
        }
        else if (appName.toLowerCase() === 'taxhawk') {
                user.isPasskeyTurnedOn = JSON.parse(process.env.VUE_APP_PASSKEY_ENABLED_TAXHAWK)
        }
        else if (appName.toLowerCase() === 'express1040') {
                user.isPasskeyTurnedOn = JSON.parse(process.env.VUE_APP_PASSKEY_ENABLED_EXPRESS1040)
        }
        else {
                user.isPasskeyTurnedOn = false
        }
}

export default setPasskeyFeatureFlag
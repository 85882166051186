import { reactive } from 'vue'

export const user = reactive({
        "jwt" : "",
        "captcha" : "",
        "loginData" : "",
        "loginVerifier" : "",
        "platform" : "",
        "isPasskeyAvailable" : false,
        "isPasskeyTurnedOn" : false,
        "passkeys" : [],
        "password" : ""
})
import {faqMap} from "@/functions/faqMap";

const faqService = () => {
	const api = 'https://' + process.env.VUE_APP_FAQ_API_SUBDOMAIN + '.' + sessionStorage.getItem('appName') + '.com' + process.env.VUE_APP_FAQS_API
	const apiParameter = 'https://' + process.env.VUE_APP_FAQ_API_SUBDOMAIN + '.' + sessionStorage.getItem('appName') + '.com' + process.env.VUE_APP_FAQ_API
	const currentTaxYear = process.env.VUE_APP_CURRENT_TAX_YEAR
	let appYear
	let faqList = []
	let extraFaqList = []

	const loadFaqs = async () => {
		appYear = sessionStorage.getItem('appYear')

		if (Number(appYear) <= 2018) {
			appYear = currentTaxYear
		}

		for (let faq in faqMap) {
			if (faqMap[faq][appYear]) {
				faqList.push(faqMap[faq][appYear])
			} else {
				extraFaqList.push(faqMap[faq][currentTaxYear])
			}
		}

		let lists = [faqList, extraFaqList]

		if (appYear === currentTaxYear || (lists[0].length !== 0 && lists[1].length === 0)) {
			await fetchFaqData(lists[0])
		}
		else if (lists[0].length === 0 && lists[1].length !== 0) {
			await fetchFaqData(lists[1])
		}
		else {
			for (let list of lists) {
				if (lists.indexOf(list) > 0) {
					appYear = currentTaxYear
				}
				await fetchFaqData(lists[lists.indexOf(list)])
			}
		}
	}

	const fetchFaqData = async (list) => {
		let text

		await fetch(api, {
			method: 'post',
			credentials: "include",
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify({
				"faq_ids": list,
				"year": appYear,
				"source": "Taxes Auth",
				"is_freefile": window.location.href.includes("FREEFILE") ? "Y" : "N",
				"is_not_freefile": "N",
				"app_name": sessionStorage.getItem('appName'),
				"visibility": [0, 2],
				"url": apiParameter
			})
		}).then(res => {
			return res.json();
		}).then(data => {
			list.forEach(faqId => {
				if (data[faqId].text.includes('href="')) {
					text = data[faqId].text
					text = text.split('href="')
					text.shift()
					text.forEach(link => {
						if (link.includes("https://auth")) {
							let paramList = JSON.parse(sessionStorage.getItem('urlParamString'))
							let appYear = JSON.parse(sessionStorage.getItem('appYear'))
							let params
							if (link.includes('?')) {params = '&'}
							else {params = '?'}
							paramList.forEach(param => {
								params += param + '&'
							})
							params += 'appYear=' + appYear
							let oldLink = link.split('"')[0]
							let newLInk = oldLink + params
							data[faqId].text = data[faqId].text.replace(oldLink, newLInk)
						}
					})
				}
				sessionStorage.setItem("FAQ-" + faqId, JSON.stringify(data[faqId]))
			})
			sessionStorage.setItem('faqs' + appYear + 'Loaded', 'true')
		}).catch((error) => {
			console.log(error)
		})
	}

	return {
		loadFaqs
	}
}

export default faqService
import {user} from "@/functions/user";
import {getBannerProducts} from "@/functions/ProductBanner";

const getUrlParameters = () => {
    let query = window.location.search;
    let urlParams = new URLSearchParams(query);
    let paramsArray = []
    let queryArray
    let currentParamsArray = []

    if (urlParams.has('FREEFILE')) {
        sessionStorage.setItem('isFreeFile', 'true')
    }

    if (urlParams.has('NEWTAX')) {
        sessionStorage.setItem('NEWTAX', 'true')
    }

    query = query.replace('?','')
    queryArray = query.split('&')
    queryArray.forEach(param => {
        if (param.includes('errorMessage=')) {
            sessionStorage.setItem('encodedErrorMessage', param.replace('errorMessage=',''))
        }
    })

    urlParams.forEach((value, key) => {
        if (value && (value !== 'errorMessage' && key !== 'appYear')) {
            sessionStorage.setItem(key, value)
        }
        else {
            paramsArray.push(key)
        }
    })

    if (sessionStorage.getItem('urlParamString')) {
        currentParamsArray = JSON.parse(sessionStorage.getItem('urlParamString'))
        paramsArray.forEach((value)=> {
            if (!currentParamsArray.includes(value)) {
                currentParamsArray.push(value)
            }
        })

        sessionStorage.setItem('urlParamString', JSON.stringify(currentParamsArray))
    }
    else {
        sessionStorage.setItem('urlParamString', JSON.stringify(paramsArray))
    }

    getBannerProducts()

    user.platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
}

export default getUrlParameters
<template>
  <div data-testId="privacy-modal-backdrop" class="modal-backdrop" @click.native="$emit('close')">
    <div @click.stop class="modal" role="dialog" aria-labelledby="privacy-modal">

      <div class="modal-content">
        <header class="modal-header" id="privacy-modal">
          <h1>Privacy Policy</h1>
          <button id="privacyModalCloseBtn" type="button" class="btn-close" @click="$emit('close')" aria-label="Close modal"></button>
        </header>

        <section class="modal-body" id="privacy-modal-content">

          <div class="w-100 mb-4">
            <a href="//privacy.trustarc.com/privacy-seal/validation?rid=c42c3969-4ba9-45a8-a024-d06b6af0032e" target="_blank">
              <img style="border: none" src="//hostedseal.trustarc.com/privacy-seal/seal?rid=c42c3969-4ba9-45a8-a024-d06b6af0032e" alt="TRUSTe"/>
            </a>
          </div>

          <p>Updated 1/7/2025</p>

          <p>
            This privacy policy applies to www.{{appName}}.com, which is owned and operated by TaxHawk, Inc. ("TaxHawk") and describes how TaxHawk collects and uses the personally identifiable information you provide on this website. It also describes the choices available to you regarding our use of your personally identifiable information and how you can access and update this information. TaxHawk treats your financial and personally identifiable information as confidential and will not sell or rent any information.
          </p>

          <h2 class="t4 mt-5">Information Collection and Use</h2>
          <p>TaxHawk, Inc. is the sole owner of the information collected on www.{{appName}}.com. Information is collected in order for taxpayers to prepare and electronically file their tax returns. Tax return information is disclosed to appropriate government and financial institutions to file tax returns and comply with Internal Revenue Service requirements. The data collected includes everything that is needed to prepare a tax return, including personally identifiable information, such as names, Social Security numbers, birth dates, mailing address, email address, bank account information, and any tax-related information (e.g., W-2s and 1099 forms) (collectively, “personal information”). If a purchase is made, payment information, such as credit card information, will be collected. Information may also be collected from customers who contact us for support. The information collected, such as a phone number, will be used to research and resolve customer questions.</p>

          <p>In addition, we collect information about your internet connection, IP address and usage details through automatic tracking technologies as discussed below.</p>

          <h2 class="t4 mt-5">Data Security</h2>
          <p>TaxHawk maintains physical, electronic, and procedural safeguards that comply with applicable law and federal standards. When users submit sensitive information via the website, it is protected both online and offline. TaxHawk uses Secure Socket Layer (SSL) encryption technology to protect all sensitive information. SSL technology is the most commonly used method on the Internet to safely transmit data. Access to your personal information is protected by a password, of your choosing, in order to maintain security for your account. Only you, or someone with whom you share your password, can access your account to make changes to your return or account information.</p>

          <p>Along with using SSL encryption to protect sensitive information online, we also take great care to protect user information offline. The {{appName}}.com servers are located in secure data storage facilities with strict security measures and procedures in place. All user information, not just the sensitive information mentioned above, is restricted to only key employees who need the information to perform a specific job function (Example: the customer support manager). Furthermore, all employees are kept up to date on company security and privacy best practices. Before every tax season, as well as any time new policies are added, employees of TaxHawk are notified and/or reminded about the importance placed on privacy, and what they can do to ensure the security of user information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. If you have any further questions about the security at TaxHawk, you can send an email to <a :href="'mailto:webmaster@'+appName+'.com'">webmaster@{{appName}}.com</a>.</p>

          <h2 class="t4 mt-5">Business Transitions</h2>
          <p>In the event TaxHawk evaluates or conducts a business transition whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, such as a merger, being acquired by another company, or selling a portion of its assets, the personal information of users will, in most instances, be part of the assets transferred. Users will be notified, via email and/or through a prominent notice on the {{appName}}.com website, 30 days prior to a change of ownership or control of their personal information. If as a result of the business transition, the personal information will be used in a manner different from that stated at the time of collection, users will be given notice consistent with our notification of changes section.</p>

          <p>We may use information that we collect about you or that you provide to us, including any personal information for marketing and advertising services consistent with our information sharing section. We may provide a referral service, allowing you to inform a friend about our products. We will ask you for your friend's name and email address. We will automatically send your friend a one-time email inviting them to visit the site. TaxHawk stores this information for the sole purpose of sending this one-time email and tracking the success of our referral program. Your friend may contact us at <a :href="'mailto:webmaster@'+appName+'.com'">webmaster@{{appName}}.com</a> to request that we remove this information from our database. Referral email addresses are automatically deleted according to our data retention policy.</p>

          <h2 class="t4 mt-5">Data Retention Policy</h2>
          <p>All data collected by us will be retained for a maximum period of eight (8) years calculated from October 31st of the IRS tax filing deadline year in which the return is due. Data will be deleted after October 31st of the 8th year following the IRS tax filing deadline year, regardless of the year the return is actually filed.</p>

          <p>For example:</p>
          <ul class="text-md">
            <li>Data collected for a 2024 tax return (which has an IRS tax filing deadline in 2025) will be retained until October 31, 2033.</li>
            <li>Data collected for a 2016 tax return (which has an IRS tax filing deadline in 2017) will be retained until October 31, 2025. Even if the return was filed in a later year, such as 2024.</li>
          </ul>

          <p>This retention policy applies to all data collected for a given tax year regardless of whether an account is active or inactive. All data collected by a third-party service provider is subject to the service provider's data retention policy.</p>

          <h2 class="t4 mt-5">Usage Information</h2>
          <p>Like most websites, {{appName}}.com uses log files and analytical software to track website usage. The information collected may include IP addresses, log information, device type, unique device identifiers, browser types, browser configuration, internet service providers (ISP), referring/exit pages, platform types, and number of clicks to analyze trends for use in administering and improving the site.</p>

          <h2 class="t4 mt-5">Tracking Technologies / Cookies</h2>
          <p>Technologies such as cookies, local shared objects or similar technology (“Cookies”) are used by {{appName}}.com in order to manage the site, see trends, and to improve the user experience. If your browser rejects cookies, you may still use our site, but your ability to use some features or areas of our site may be limited. The types of cookies or tracking technologies we may use are defined below:</p>

          <p>Essential cookies are necessary for {{appName}}.com to function properly.</p>
          <p>Performance and Functionality cookies are used to enhance the performance and functionality of {{appName}}.com but are not essential to its use. Examples of these cookies include: A/B testing version control, page load speed, etc.</p>
          <p>Advertising cookies and tracking technologies are used to make advertising {{appName}}.com more relevant to users and to stop ads from reappearing on the advertisers' networks or properties. We use advertising platforms to provide personalized advertising on their properties and ad networks. Advertisers may use third-party cookies to provide personalized {{appName}}.com advertising.</p>
          <p>Analytics cookies and tracking technologies are used to help understand how the website is being used. Information gathered by analytics is anonymous and viewed in aggregate to see broader traffic trends.</p>

          <h2 class="t4 mt-5">Retargeting and Personalized Advertising</h2>
          <p>We work with third-party advertising partners to show ads for our products and services across the internet. These partners use cookies and/or other tracking technologies to gather information about your browsing activity on our website to deliver personalized ads based on your interests. We do not upload customer lists to our third-party advertisers. This practice, known as “retargeting” or “remarketing,” helps us remind you of our products and services and improve the relevance of ads you see.</p>

          <p>If you complete certain actions on our website, such as making a purchase, creating an account, or signing in to our services, we may work with our advertising partners to stop showing you retargeting ads. These actions indicate that you have already engaged with our services, and we aim to enhance your experience by reducing irrelevant ads.</p>

          <p>We do not share personally identifiable information (PII) with our advertising partners for retargeting purposes. The data collected is solely used to improve the relevance of our ads.</p>

          <h2 class="t4 mt-5">Managing Your Ad Preferences</h2>
          <p>You have control over your advertising experience and can opt out of personalized ads in various ways:</p>

          <ul class="text-md">
            <li>Google Ads: Manage your ad settings at <a href="https://adssettings.google.com/" target="_blank">Google Ads Settings</a>.</li>
            <li>Industry-Wide Opt-Out: Opt out of targeted ads from multiple ad networks through the Digital Advertising Alliance at optout.aboutads.info or the Network Advertising Initiative at optout.networkadvertising.org.</li>
          </ul>

          <h2 class="t4 mt-5">Do Not Track (DNT) Signals</h2>
          <p>Our website respects “Do Not Track” (DNT) signals set in your browser settings. If DNT is enabled, we limit certain tracking practices; however, you may still see general ads that are not personalized.</p>

          <h2 class="t4 mt-5">Artificial Intelligence (AI) and Automated Processing</h2>
          <p>When using digital media (PDF, photo, etc.) imports and/or uploads on {{appName}}.com, any information submitted by the customer through this service may be processed using automated (AI) and/or manual (human) methods. The information given may be used to improve and develop and train our artificial intelligence and other machine learning models. We implement robust safeguards in our use of AI to maintain security and compliance, ensuring sensitive information is protected and handled responsibly. Any sensitive information is anonymized or excluded from use. We do not share customer data or trained AI models with any third party. When leveraging cloud infrastructure, all data is securely managed and maintained under TaxHawk’s exclusive control.</p>

          <h2 class="t4 mt-5">hCaptcha</h2>
          <p>We use the hCaptcha anti-bot service (hereinafter "hCaptcha") on our website. This service is provided by Intuition Machines, Inc., a Delaware US Corporation ("IMI"). hCaptcha is used to check whether the data entered on our website (such as on a login page or contact form) has been entered by a human or by an automated program. To do this, hCaptcha analyzes the behavior of the website or mobile app visitor based on various characteristics. This analysis starts automatically as soon as the website or mobile app visitor enters a part of the website or app with hCaptcha enabled. For the analysis, hCaptcha evaluates various information (e.g. IP address, how long the visitor has been on the website or app, or mouse movements made by the user). The data collected during the analysis will be forwarded to IMI. hCaptcha analysis in the "invisible mode" may take place completely in the background. Website or app visitors are not advised that such an analysis is taking place if the user is not shown a challenge. IMI acts as a "service provider" for the purposes of State Privacy Acts. For more information about hCaptcha and IMI's privacy policy and terms of use, please visit the following links: <a href="https://www.hcaptcha.com/privacy" target="_blank">https://www.hcaptcha.com/privacy</a> and <a href="https://www.hcaptcha.com/terms" target="_blank">https://www.hcaptcha.com/terms</a>.</p>

          <h2 v-if="!isFreefile" class="t4 mt-5">Contests and Sweepstakes</h2>
          <p v-if="!isFreefile">Contests and Sweepstakes have their own terms and conditions and should be referenced before entering. <a :href="landingGiveawayLink" target="_blank">Sweepstakes link</a></p>

          <h2 class="t4 mt-5">Communications from TaxHawk</h2>
          <p>Your contact information (email address and/or SMS information) may be used to keep you updated on the status of your return, remind you of important dates on the tax calendar, resolve any problems with transmission of your tax return, request customer feedback, provide product and fulfillment information, and confirmation of credit card or direct-debit payments. If you e-file your tax return, an email and/or SMS text will be sent to you stating whether the IRS has accepted or rejected your e-filed tax return. If problems occur with the transmission of your tax return, a customer support email may be sent out to explain the problem. We'll also send you a confirmation email as a receipt when you pay for any TaxHawk services.</p>

          <p>Occasionally, TaxHawk will send out tax information or promotional material dealing with our products. These are considered optional communications and you, as the recipient, can specify whether or not you wish to receive this type of communication. This can be done while creating your account, by using the Edit User Profile portion of the application after signing in, by clicking on the "Unsubscribe" link in the footer of every optional email or by following the instructions found at the following link: <a :href="landingUnsubscribeLink" target="_blank">https://www.{{appName}}.com/unsubscribe</a>.</p>

          <h2 class="t4 mt-5">Service Announcements</h2>
          <p>On rare occasions, it is necessary to send out a strictly service-related announcement. For instance, if service is temporarily suspended for maintenance, TaxHawk might send users a notification via email.</p>

          <h2 class="t4 mt-5">Customer Support</h2>
          <p>Customer support for TaxHawk is provided through our tax application, email, or phone. When a customer support request is received, we will reply via the customer support portion of the application, email, or phone. Before you finish filing your tax return, if your personal information changes, or if you no longer desire to use our service, you may correct, update or delete it by signing in to your account and making any changes. If you have any questions regarding this process, you may contact us at <a :href="'mailto:webmaster@'+appName+'.com'">webmaster@{{appName}}.com</a>; we will respond within a reasonable timeframe. Upon request and verification of your identity, TaxHawk will provide you with information about whether we hold any of your personal information. Once the IRS accepts your e-filed tax return, no changes can be made to your account that would change any of the information on the tax return.</p>

          <p>We may use artificial intelligence (AI) to answer customer support inquiries. If AI is used, any information submitted by the customer through the customer support tool will be processed using automated (AI) and/or manual (human) methods. The information given is solely used for processing the questions submitted and improving the answers provided, and not for any other purpose.</p>

          <p>Subject to our Data Retention Policy, we will retain your information for as long as needed to provide you services. We may retain and use your information outside of that policy only as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

          <h2 class="t4 mt-5">Legal Disclosures</h2>
          <p>Though every effort is made to preserve user privacy, in certain situations, TaxHawk may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. We may also disclose your personal information when required by law wherein we have a good-faith belief that such action is necessary to protect our rights, protect your safety or the safety of others, to investigate fraud to comply with a current judicial proceeding, a court order, respond to a government request, bankruptcy proceedings or similar legal process served on our website.</p>

          <h2 class="t4 mt-5">Information About Minors</h2>
          <p>{{appName}}.com is not intended for children under 13 years of age. No one under age 13 may provide any information to {{appName}}.com. We do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at <a :href="'mailto:webmaster@'+appName+'.com'">webmaster@{{appName}}.com</a>. To learn more about the Children’s Online Privacy Act (COPPA), please visit <a href="https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy" target="_blank">https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy</a>.</p>

          <h2 class="t4 mt-5">Information Sharing</h2>
          <p>As a tax preparer, we strive to comply with Internal Revenue Code Section 7216 with respect to the disclosure and use of tax return information. As such, we do not, and will not, sell your personal information to third parties. We only share your personal information with third parties in ways that are described in this privacy policy.</p>

          <h2 class="t4 mt-5">Data Storage</h2>
          <p>All information provided to us is maintained by us on servers located in the United States. We take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law. By providing such information through the Website, you hereby expressly consent to such processing of your data in the United States.</p>

          <p>To comply with European Union (EU) and European Economic Area (EEA) regulatory requirements, we do not provide service or intentionally collect data from EU or EEA residents. By using our services, customers represent that they are not EU or EEA residents.</p>

          <h2 class="t4 mt-5">Service Providers</h2>
          <p>We may provide necessary personal information to companies that provide services, such as shipping your order, SMS authentication, customer support inquiries, fraud detection, credit card processing, extracting and processing documents, and sending emails. These companies are only authorized to use this information to provide these services.</p>

          <p>Customer reviews and information provided as part of the review are collected, stored, and managed using a service provider. The review and some details provided by you may be shared with the public. Account verification codes sent through text messaging or voice calls are provided by third-party services. A customer's phone number will be forwarded to the service in order to send the account verification code.</p>

          <h2 class="t4 mt-5">Credit Card Processing</h2>
          <p>If during the use of our application you order a product that requires payment, your credit card or direct-debit payment will be processed by a payment processing company. The payment processing companies that we use do not retain, share, store or use personal information for any secondary purposes. No credit card information will be collected unless there is a payment due. Your credit card data is only used to process your payment.</p>

          <p>If you choose to pay any federal or state taxes owed by credit card, that transaction will be handled by a third-party service and is subject to the service's terms of use and privacy policy.</p>

          <h2 class="t4 mt-5">Links to Third Party Websites</h2>
          <p>Our website includes links to other websites whose privacy practices may differ from those of TaxHawk. If you submit any personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit. TaxHawk does not control these third parties’ tracking technologies or how they may be used and is not responsible for the content or privacy policies of such third-party sites.</p>

          <h2 class="t4 mt-5">Notification of Changes</h2>
          <p>If we make material changes to how we treat our users' personal information, we will notify you here, by email, or by means of a notice on the footer of our homepage and sign-in screens. We reserve the right to modify this privacy policy at any time. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our website and this privacy policy to check for any changes.</p>

          <h2 class="t4 mt-5">State Privacy Laws</h2>
          <p>Please visit <a :href="landingStatePrivacyLink" aria-label="the state privacy page" target="_blank">this page</a> for more information on California Consumer Privacy Act (CCPA).</p>

          <p>Other state privacy acts defer to Gramm-Leach-Bliley Act (GLBA) for consumer privacy protections. As a tax preparation software provider, we comply with GLBA and state privacy laws if they impose greater restrictions and requirements than GLBA. This privacy policy explains your privacy rights as our customer. Please visit <a :href="glbaDownloadPath" class="ta" download target="_blank">this page</a> for our GLBA notice.</p>

          <h2 class="t4 mt-5">Contact Us</h2>
          <p>If you have additional questions regarding TaxHawk's privacy or security policies, please send your question to <a :href="'mailto:webmaster@'+appName+'.com'">webmaster@{{appName}}.com</a>.</p>
          <p>Or, address your written question to:</p>
          <p>
            <span class="fw-bold">TaxHawk - Privacy</span><br>
            1366 East 1120 South <br>
            Provo, UT 84606
          </p>
          <p>If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third-party dispute resolution provider (free of charge) at <a href="https://feedback-form.truste.com/watchdog/request" target="_blank">https://feedback-form.truste.com/watchdog/request</a>.</p>
        </section>
      </div>

      <footer class="modal-footer">
        <button data-testId="privacy-footer-close-btn" type="button" class="btn btn-white" @click="$emit('close')" aria-label="Close modal">Close</button>
      </footer>

    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const appName = sessionStorage.getItem('appName')
const appYear = sessionStorage.getItem('appYear')
const isFreefile = ref(false)
const landingLink = ref()
const landingSoftwareLink = ref()
const landingPrivacyLink = ref()
const landingStatePrivacyLink = ref()
const landingUnsubscribeLink = ref()
const landingGiveawayLink = ref('https://www.' + appName.toLowerCase() + '.com/giveaway')
const glbaDownloadPath = ref('https://www.' + appName.toLowerCase() + '.com/forms/' + appYear + '/Annual_Privacy_Notice_Oct_2024_')

if (sessionStorage.getItem('isFreeFile') === 'true' && appName.toLowerCase() === 'freetaxusa')
{
  isFreefile.value = true
  landingLink.value = 'https://www.' + appName.toLowerCase() + '.com/freefile' + appYear
  landingSoftwareLink.value = 'https://www.' + appName.toLowerCase() + '.com/freefile' + appYear
  landingPrivacyLink.value = 'https://www.' + appName.toLowerCase() + '.com/freefile' + appYear + '/privacy'
  landingStatePrivacyLink.value = 'https://www.' + appName.toLowerCase() + '.com/freefile' + appYear + '/state-privacy'
  landingUnsubscribeLink.value = 'https://www.' + appName.toLowerCase() + '.com/freefile' + appYear + '/unsubscribe'
  glbaDownloadPath.value += 'Free_File.pdf'
}
else
{
  if (appName.toLowerCase() === 'taxhawk') {
    glbaDownloadPath.value += 'TH.pdf'
  } else if (appName.toLowerCase() === 'express1040') {
    glbaDownloadPath.value += 'E1040.pdf'
  } else {
    glbaDownloadPath.value += 'FTUSA.pdf'
  }
  landingLink.value = 'https://www.' + appName.toLowerCase() + '.com/'
  landingSoftwareLink.value = 'https://www.' + appName.toLowerCase() + '.com/software'
  landingPrivacyLink.value = 'https://www.' + appName.toLowerCase() + '.com/privacy'
  landingStatePrivacyLink.value = 'https://www.' + appName.toLowerCase() + '.com/state-privacy'
  landingUnsubscribeLink.value = 'https://www.' + appName.toLowerCase() + '.com/unsubscribe'
}

</script>
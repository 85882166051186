import setAppYear from "@/functions/setAppYear";

const getAppYear = () => {
	let appYear = sessionStorage.getItem("appYear");
	if (!appYear) {
		setAppYear()
		appYear = sessionStorage.getItem("appYear");
	}
	return parseInt(appYear)
}

export default getAppYear
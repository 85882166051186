<template>
<div id="loadingScreen" class="d-none" >
  <span class="pt-5">Loading</span>
  <img style="width: 56px;" class="mt-2" alt="please wait while the screen in loading" src="~@/assets/img/spinner.gif">
</div>
</template>

<style lang="scss" scoped>
#loadingScreen {
  background-color: $white;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 100;
  flex-direction: column;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @include breakpoint(sm) {
    left: calc(var(--bs-gutter-x)*0.5);
    right: calc(var(--bs-gutter-x)*0.5);
  }


  span {
    font-size: 26px;
    margin-bottom: 6px;
    line-height: 24px;
  }
}
</style>
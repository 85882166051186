<template>
  <nav class="navbar navbar-expand-lg fixed-top border-bottom">
      <div class="navigation-content">
        <div class="d-flex align-items-center">
          <a id="firstTabIndex" tabindex="1" class="navbar-brand" :href="useAuthenticatedNavLink ? undefined : landingURL" @click="useAuthenticatedNavLink ? redirectToWelcomePage() : null" :style="{ cursor: 'pointer'}">
            <img id="logo" :src="logoPath" alt="Go to website homepage">
          </a>
          <div id="edition" class="d-flex align-items-center">
            <span>{{ appYear }}</span>
          </div>
        </div>
      </div>
  </nav>
</template>

<script setup>
import { useRoute } from 'vue-router'
import getAppName from "@/functions/getAppName"
import getAppYear from "@/functions/getAppYear"
import {ref, watch} from "vue";
import {refreshTokens} from '@/functions/authenticatedRequest'

const appName = getAppName()
const appYear = getAppYear()
const logoPath = require('../assets/img/' + appName + '.svg')
const landingURL = ref()
const route = useRoute()
const useAuthenticatedNavLink = ref(false)
let serverType = sessionStorage.getItem('serverType')

if (serverType === 'TEST' || serverType === 'STAGE')
{
  landingURL.value = 'https://' + serverType.toLowerCase() + '.' + appName.toLowerCase() + '.com'
}
else
{
  landingURL.value = 'https://www.' + appName.toLowerCase() + '.com'
}
if  (location.href.includes('FREEFILE') || sessionStorage.getItem('isFreeFile'))
{
  landingURL.value += '/freefile' + process.env.VUE_APP_CURRENT_TAX_YEAR
}

watch(
    () => route.path,
    (newPath) => {
      useAuthenticatedNavLink.value = newPath === '/passkey';  // Just passkey for now
    },
    { immediate: true }
);

const redirectToWelcomePage = () => {
  refreshTokens().then(() => {
    window.isIntentionalNavigation = true;
    let parameters = {
      'tp': 199,
      'thn': sessionStorage.getItem('thn')
    }
    let redirectUrl = sessionStorage.getItem('redirectUrl')

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = redirectUrl;
    document.body.appendChild(form);

    for (const key in parameters) {
      const formField = document.createElement('input');
      formField.type = 'hidden';
      formField.name = key;
      formField.value = parameters[key];
      form.appendChild(formField);
    }
    form.submit()
  });
}
</script>

<style lang="scss" scoped>
.navbar {
  background-color: $white;
  padding-bottom: 0;
  padding-top: 0;
  height: 48px;

  @include breakpoint(sm) {
    height: 64px;
  }
}

.navigation-content {
  @include contentWidth(nav);

  max-width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  position: relative;
  width: 1152px;

  @include breakpoint(sm) {
    margin: 0 16px;
    padding: 0;
    max-width: 100%;
  }

  @include breakpoint(xl) {
    margin: 0 2rem;
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.navbar-brand {
  img {
    max-width: 200px;
  }
}

#logo {
  max-width: 152px;
  margin-bottom: 6px;

  @include breakpoint(sm) {
    max-width: 200px;
  }
}

#edition {
  color: $dark-gray;
  font-size: 1.25rem;
  font-weight: $font-weight-bold;
  margin-top: 2px;

  @include breakpoint(sm) {
    margin-top: 0;
  }
}
</style>
<template>
  <footer v-if="isValidBrowserOSConfig" class="bg-gray text-center">
    <div class="container py-5">
      <div class="d-flex justify-content-center mb-3">
        <button type="button" class="text-xs text-black me-3" @click="showModal('privacy')">Privacy</button>&emsp;
        <button type="button" class="text-xs text-black me-3" @click="showModal('security')">Security</button>&emsp;
        <button type="button" class="text-xs text-black" @click="showModal('accessibility')">Accessibility</button>
      </div>
      <p class="text-xs">© 2002-{{ currentDateYear }} {{ appName }}, a TaxHawk, Inc. owned and operated website - All Rights Reserved</p>
    </div>
    <PrivacyModal id="privacyModal" class="modal-backdrop-hidden" @close="closeModal('privacy')" />
    <SecurityModal id="securityModal" class="modal-backdrop-hidden" @close="closeModal('security')" />
    <AccessibilityModal id="accessibilityModal" class="modal-backdrop-hidden" @close="closeModal('accessibility')" />
  </footer>
</template>

<script setup>
import {onBeforeMount, ref} from 'vue'
import getModal from "../functions/getModal"
import PrivacyModal from "../views/modal/Privacy"
import SecurityModal from "../views/modal/Security"
import AccessibilityModal from "../views/modal/Accessibility"

const {showModal, closeModal} = getModal()
const isValidBrowserOSConfig = ref(true)
const appYear = ref()
const currentDateYear = new Date().getFullYear()
appYear.value = sessionStorage.getItem('appYear')
const appName = sessionStorage.getItem('appName')

onBeforeMount(() => {
  if (sessionStorage.getItem('isValidBrowserOSConfig') === 'false') {
    isValidBrowserOSConfig.value = false
  }
})
</script>

<style lang="scss" scoped>
footer {
  .container {
    width: 1200px;
    max-width: 100%;

    @include breakpoint(sm) {
      max-width: 95%;
    }
  }

  ul {
    a {
      color: $dark-gray;
    }
  }
}
</style>
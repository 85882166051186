import {createRouter, createWebHistory} from 'vue-router'
import PageNotFound from '../views/PageNotFound.vue'
import getLoading from "@/functions/getLoading";

const { hideLoading } = getLoading()
const hostname = new URL(location.href).hostname
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let appName
let appTradeName = document.createElement('span')
let appYear = ''
if (hostname.includes('freetaxusa'))
{
  appName = 'FreeTaxUSA'
}
else if (hostname.includes('taxhawk'))
{
  appName = 'TaxHawk'
}
else if (hostname.includes('express1040')) 
{
  appName = 'Express1040'
}
appTradeName.innerHTML = appName + '&reg;'
appTradeName = appTradeName.innerHTML
sessionStorage.setItem('appName', appName)
sessionStorage.setItem('appTradeName', appTradeName)

let indexPage
if (location.href.includes('NEWTAX') || sessionStorage.getItem('NEWTAX'))
{
  indexPage = 'LoginNew'
}
else
{
  indexPage = 'Login'
}

if (urlParams.get('appYear') || sessionStorage.getItem('appYear')) {
  if (urlParams.get('appYear') && urlParams.get('appYear') >= 2015) {
    appYear = urlParams.get('appYear') + ' '
  }
  else if (sessionStorage.getItem('appYear')) {
    appYear = sessionStorage.getItem('appYear') + ' '
  }
}

const routes = [
  {
    path: '/',
    name: indexPage,
    component: () => import('../views/' + indexPage),
    props: true,
    meta: {
      title: appTradeName + ' - ' + appYear + 'Sign in',
      canonical: 'https://auth.'+ appName +'.com',
      metaTags: [
        {
          name: 'description',
          content: 'description'
        },
        {
          name: 'keywords',
          content: 'keywords'
        }
      ]
    },
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: '/browser-entry-denied',
    name: 'LoginDenied',
    component: () => import('../views/LoginDenied'),
    props: true,
    meta: {
      title: appTradeName + ' - Browser Entry Denied',
      metaTags: [
        {
          name: 'description',
          content: 'browser denied'
        },
        {
          name: 'keywords',
          content: 'denied'
        },
        {
          name: "robots",
          content: "noindex, nofollow",
        }
      ]
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About'),
    meta: {
      title: appTradeName + ' - About ' + appName,
    },
  },
  {
    path: '/AuthAppVerification',
    name: 'AuthAppVerification',
    component: () => import('../views/AuthAppVerification'),
    props: true,
    meta: {
      title: appTradeName + ' - Authenticator App Verification',
    },
  },
  {
    path: '/confirmEmail',
    name: 'ConfirmEmail',
    component: () => import('../views/ConfirmEmail.vue'),
    props: true,
    meta: {
      title: appTradeName + ' - Confirm Email',
    },
  },
  {
    path: '/EmailVerification',
    name: 'EmailVerification',
    component: () => import('../views/EmailVerification'),
    props: true,
    meta: {
      title: appTradeName + ' - Email Verification',
    },
  },
  {
    path: '/EmergencyCodeVerification',
    name: 'EmergencyCodeVerification',
    component: () => import('../views/EmergencyCodeVerification'),
    props: true,
    meta: {
      title: appTradeName + ' - Emergency Code Verification',
    },
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword'),
    props: true,
    meta: {
      title: appTradeName + ' - Forgot ' + appName + ' Password',
    },
  },
  {
    path: '/ForgotUsername',
    name: 'ForgotUsername',
    component: () => import('../views/ForgotUsername'),
    props: true,
    meta: {
      title: appTradeName + ' - Forgot ' + appName + ' Username',
    },
  },
  {
    path: '/NewUser',
    name: 'NewUser',
    component: () => import('../views/NewUser'),
    props: true,
    meta: {
      title: appTradeName + ' - New Account Setup',
    },
  },
  {
    path: '/passkey',
    name: 'Passkey',
    component: () => import('../views/Passkey'),
    meta: {
      title: appTradeName + ' - Passkey',
    },
  },
  {
    path: '/passkey-upsell',
    name: 'PasskeyUpsell',
    component: () => import('../views/PasskeyUpsell'),
    meta: {
      title: appTradeName + ' - Passkey',
    },
  },
  {
    path: '/ReclaimAccount',
    name: 'ReclaimAccount',
    component: () => import('../views/ReclaimAccount'),
    props: true,
    meta: {
      title: appTradeName + ' - Let\'s help you get into your account',
      canonical: 'https://auth.'+ appName +'.com/ReclaimAccount',
    },
  },
  {
    path: '/reclaimAccountList',
    name: 'ReclaimAccountList',
    component: () => import('../views/ReclaimAccountList'),
    props: true,
    meta: {
      title: appTradeName + ' - Choose Account Username',
    },
  },
  {
    path: '/reclaimAccountMethod',
    name: 'ReclaimAccountMethod',
    component: () => import('../views/ReclaimAccountMethod'),
    props: true,
    meta: {
      title: appTradeName + ' - How to Receive Password Reset Code',
    },
  },
  {
    path: '/ReclaimChangePassword',
    name: 'ReclaimChangePassword',
    component: () => import('../views/ReclaimChangePassword'),
    props: true,
    meta: {
      title: appTradeName + ' - Change Password',
    },
  },
  {
    path: '/ReclaimResetLogin',
    name: 'ReclaimResetLogin',
    component: () => import('../views/ReclaimResetLogin'),
    props: true,
    meta: {
      title: appTradeName + ' - New Password Saved',
    },
  },
  {
    path: '/ReclaimResetPasswordCode',
    name: 'ReclaimResetPasswordCode',
    component: () => import('../views/ReclaimResetPasswordCode'),
    props: true,
    meta: {
      title: appTradeName + ' - Password Reset Code',
    },
  },
  {
    path: '/SentUsername',
    name: 'SentUsername',
    component: () => import('../views/SentUsername'),
    props: true,
    meta: {
      title: appTradeName + ' - We emailed your username to you',
    },
  },
  {
    path: '/TextVerification',
    name: 'TextVerification',
    component: () => import('../views/TextVerification'),
    props: true,
    meta: {
      title: appTradeName + ' - Text Verification',
    },
  },
  {
    path: '/verifyWithTwoStepMethod',
    name: 'VerifyWithTwoStepMethod',
    component: () => import('../views/VerifyWithTwoStepMethod'),
    props: true,
    meta: {
      title: appTradeName + ' - How to Receive Two Factor Code',
    },
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const nearestWithCanonical = to.matched.slice().reverse().find(r => r.meta && r.meta.canonical);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  if(nearestWithCanonical) {
    const canonical = document.createElement('link')
    canonical.setAttribute('rel', 'canonical')
    canonical.setAttribute('href', nearestWithCanonical.meta.canonical)
    document.head.append(canonical)
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
});

router.afterEach(() => {
  document.getElementById('firstTabIndex').focus()
  hideLoading()
});

export default router

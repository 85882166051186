const setAppYear = () => {
    const currentTaxYear = process.env.VUE_APP_CURRENT_TAX_YEAR
    const minPriorTaxYear = process.env.VUE_APP_MIN_PRIOR_TAX_YEAR
    const params = new URLSearchParams(window.location.search)
    let appYear = params.get('appYear')
    let appYearInt = parseInt(appYear)
	if (isNaN(appYearInt)) {
		appYearInt = process.env.VUE_APP_CURRENT_TAX_YEAR;
	}

    if (appYear || sessionStorage.getItem('appYear')) {
        if (appYear && appYearInt >= minPriorTaxYear)
        {
            sessionStorage.setItem('appYear', appYearInt.toString())
        }
        else if (!sessionStorage.getItem('appYear'))
        {
            sessionStorage.setItem('appYear', currentTaxYear)
        }
    } else {
        sessionStorage.setItem('appYear', currentTaxYear)
    }
}

export default setAppYear
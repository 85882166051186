const setAppName = () => {
    let appName
    let favicon = document.getElementById('favicon')
    let url = location.href

    if (url.includes('freetaxusa')) {
        appName = 'FreeTaxUSA'
        favicon.setAttribute('href', 'ft-favicon.ico')
    } else if (url.includes('taxhawk')) {
        appName = 'TaxHawk'
        favicon.setAttribute('href', 'th-favicon.ico')
    } else if (url.includes('express1040')) {
        appName = 'Express1040'
        favicon.setAttribute('href', 'ex-favicon.ico')
    }
}

export default setAppName
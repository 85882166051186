import {useRouter} from "vue-router";
import getModal from "@/functions/getModal";
import errorIcon from '@/assets/img/error-icon.svg'
import warningIcon from '@/assets/img/warning-icon.svg'
import infoIcon from '@/assets/img/primary-info-icon.svg'
import sanitizeHtml from 'sanitize-html'

const {showModal} = getModal()

const getToastAlert = () => {
    const router = useRouter()
	const alert429 = [{
		'type': 'ERROR',
		'title': '',
		'message': 'You\'ve made too many requests in a short period. Please wait a minute before trying again.'
	}]

    let toastAlertAreaDiv
    let icon
    let newToastAlert
    let newToastAlertInnerDiv
    let newToastContent
    let newToastAlertFixItBtn
    let faqIcon

    const displayDefaultError = async () => {
        await router.push('/')
        displayToastAlert()
    }

	const display429Error = () => {
		displayToastAlert(alert429)
	}

    const displayToastAlert = (alerts) => {
        hideToastAlert()
        let btnId = 1

        if (alerts) {
            if (alerts.constructor !== Array) {
                alerts = [alerts]
            }
        }
        else {
            alerts = [{
                'message': process.env.VUE_APP_DEFAULT_ERROR_MESSAGE
            }]
        }

        alerts.forEach(async (alert) => {
            if (alert.title || alert.message) {

                icon = document.createElement('img')
                icon.classList.add('toast-alert-icon')
                icon.alt = ''

                newToastAlert = document.createElement('div')
                newToastAlert.classList.add('alert-message')
                newToastAlertInnerDiv = document.createElement('div')
                newToastAlertInnerDiv.classList.add('alert-message-inner-container')
                newToastContent = document.createElement('div')
                newToastContent.classList.add('toast-alert-content')

                if (alert.isRedirectToLogin) {
                    await router.replace('/')
                }

                if (!alert.type) {
                    alert.type = 'ERROR'
                }

                if (alert.type === 'INFO') {
                    icon.src = infoIcon
                }
                else if (alert.type === 'WARNING') {
                    icon.src = warningIcon
                }
                else {
                    icon.src = errorIcon
                }

                if (alert.title) {
                    let titleTag = document.createElement('span')
                    titleTag.innerHTML = sanitizeHtml(alert.title)
                    titleTag.classList.add('d-block','fw-bold', 'text-md', 'mb-2')
                    newToastContent.appendChild(titleTag)
                }

                if (alert.message) {
                    let messageTag = document.createElement('span')
                    messageTag.classList.add('text-md')
                    messageTag.innerHTML = sanitizeHtml(alert.message)

                    if (alert.faq) {
                        faqIcon = document.createElement('button')
                        faqIcon.classList.add('help-a')
                        faqIcon.type = "button"
                        faqIcon.onclick = function () {showModal('faq' + alert.faq)}
                        faqIcon.innerHTML = '<span class="visually-hidden">click to open faq</span>'
                        messageTag.appendChild(faqIcon)
                    }

                    newToastContent.appendChild(messageTag)
                }

                newToastAlertInnerDiv.appendChild(newToastContent)

                newToastAlert.classList.add(alert.type.toLowerCase() + '-message')

                if (alert.field) {
                    newToastAlertFixItBtn = document.createElement('button')
                    newToastAlertFixItBtn.id = 'fixItButton' + btnId
                    newToastAlertFixItBtn.classList.add('toast-alert-btn')
                    newToastAlertFixItBtn.tabIndex = 1
                    newToastAlertFixItBtn.innerHTML = 'Fix this'
                    btnId += 1
                    newToastAlertFixItBtn.onclick = function () {
                        focusOnFieldError(alert.field)
                    }
                    document.getElementById(alert.field).classList.add(alert.field.toLowerCase() + '-message')
                    document.getElementById(alert.field).classList.remove('info-message')

                    if (alert.type && alert.type !== '429') {
                        newToastAlertInnerDiv.appendChild(newToastAlertFixItBtn)
                    }
                }

                newToastAlert.appendChild(icon)
                newToastAlert.appendChild(newToastAlertInnerDiv)
                toastAlertAreaDiv = document.getElementById('toastAlertArea')
                toastAlertAreaDiv.insertBefore(newToastAlert, null)
            }
        })

        if (document.getElementById('fixItButton1')) {
            document.getElementById('fixItButton1').focus()
        }
        scrollTo(0,0)
    }

    const focusOnFieldError = (id) => {
        let fieldError
        let fieldError2
        if (id === 'confirmEmail') {
            fieldError = document.getElementById('email')
            fieldError2 = document.getElementById('confirmEmail')
            fieldError.classList.add('error-message')
            fieldError2.classList.add('error-message')
            fieldError.classList.add('error-message-shading')
            fieldError2.classList.add('error-message-shading')
            fieldError.focus()
            fieldError.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'})
            setTimeout(()=>{
                fieldError.classList.remove('error-message-shading')
                fieldError2.classList.remove('error-message-shading')
            }, 2000)
        }
        else {
            fieldError = document.getElementById(id)
            fieldError.classList.add('error-message')
            fieldError.classList.add('error-message-shading')
            fieldError.focus()
            fieldError.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'})
            setTimeout(()=>{fieldError.classList.remove('error-message-shading')}, 2000)
        }
    }

    const hideToastAlert = () => {
        if (document.getElementsByClassName('alert-message')) {
            let oldAlerts = document.getElementsByClassName('alert-message')
            for (let i = 0; i < oldAlerts.length; i++) {
                oldAlerts[i].style.display = 'none'
            }
        }
    }

    return {
        displayDefaultError,
        displayToastAlert,
	    display429Error,
        hideToastAlert
    }
}

export default getToastAlert
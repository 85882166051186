
const refreshApi = process.env.VUE_APP_REFRESH_JWT_TOKEN

async function sendAuthenticatedRequest(url, router, requestOptions) {
	let response;
	try {
		response = await fetch(url, requestOptions);
		if (response.status === 401) {
			// If response status is 401 (Unauthorized), attempt to refresh the access token
			const refreshResponse = await fetch(refreshApi, {
				credentials: 'include'
			});
			if (refreshResponse.status === 200) {
				// If refresh token succeeds, retry the original request
				response = await fetch(url, requestOptions);

				if (response.status === 401) {
					router.push('/')
				}
			} else {
				router.push('/')
			}
		}
	} catch (error) {
		console.error('Error in authenticated request:', error);
	}
	return response;
}


async function refreshTokens() {
	await fetch(refreshApi, {
		credentials: 'include'
	});
}

export {sendAuthenticatedRequest, refreshTokens};

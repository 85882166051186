const setServerType = () => {
    let url = location.host

    if (url.includes('prodtestauth'))
    {
        sessionStorage.setItem('serverType', 'PROD')
    }
    else if (url.includes('testauth'))
    {
        sessionStorage.setItem('serverType', 'TEST')
    } 
    else if (url.includes('stageauth'))
    {
        sessionStorage.setItem('serverType', 'STAGE')
    } 
    else 
    {
        sessionStorage.setItem('serverType', 'PROD')
    }
}

export default setServerType
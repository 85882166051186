import { reactive } from "vue"

// an FAQ doesn't always have the same ID in different years.
// The attribute here is the latest id for the faq, and will always match the latest year
// i.e. "faqId2024": { "2024" : "faqId2024" }
// if the faq id doesn't change or is unavailable in a certain year, the latest year will be used instead
export const faqMap = reactive({
    "144": {
            "2024": "144",
            "2023": "144",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "1893": {
            "2024": "1893",
            "2023": "1893",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "6": {
            "2024": "6",
            "2023": "6",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "6076": {
            "2024": "6076",
            "2023": "6076",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "6148": {
            "2024": "6148",
            "2023": "6148",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "6077": {
            "2024": "6077",
            "2023": "6077",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "6662": {
            "2024": "6662",
            "2023": "6662",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "6665": {
            "2024": "6665",
            "2023": "6665",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "7741": {
            "2024": "7741",
            "2023": "7741",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "7798": {
            "2024": "7798",
            "2023": "7798",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "7744": {
            "2024": "7744",
            "2023": "7744",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "7763": {
            "2024": "7763",
            "2023": "7763",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "7812": {
            "2024": "7812",
            "2023": "7812",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "7828": {
            "2024": "7828",
            "2023": "7828",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "7829": {
            "2024": "7829",
            "2023": "7829",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "8487": {
            "2024": "8487",
            "2023": "8487",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "8488": {
            "2024": "8488",
            "2023": "8488",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "8561": {
            "2024": "8561",
            "2023": "8561",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "11118": {
            "2024": "11118",
            "2023": "11118",
            "2022": "",
            "2021": "",
            "2020": "",
            "2019": "",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "11541": {
            "2024": "11541",
            "2023": "11463",
            "2022": "10769",
            "2021": "10166",
            "2020": "9581",
            "2019": "8937",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "11542": {
            "2024": "11542",
            "2023": "11464",
            "2022": "10770",
            "2021": "10167",
            "2020": "9582",
            "2019": "8938",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "11543": {
            "2024": "11543",
            "2023": "11466",
            "2022": "10772",
            "2021": "10169",
            "2020": "9584",
            "2019": "8940",
            "2018": "",
            "2017": "",
            "2016": ""
    },
    "11588": {
            "2024": "11588",
            "2023": "11465",
            "2022": "10771",
            "2021": "10168",
            "2020": "9583",
            "2019": "8939",
            "2018": "",
            "2017": "",
            "2016": ""
    }
})
import {user} from "@/functions/user";
import {useRouter} from "vue-router";
import {sendAuthenticatedRequest} from "@/functions/authenticatedRequest";
import {create, parseCreationOptionsFromJSON} from "@github/webauthn-json/browser-ponyfill";
import getModal from "@/functions/getModal";
import getToastAlert from "@/functions/getToastAlert";

const passkey = () => {
	const {displayToastAlert, hideToastAlert} = getToastAlert()
    const registerStartApi = process.env.VUE_APP_PASSKEY_REGISTER_START_API
    const registerFinishApi = process.env.VUE_APP_PASSKEY_REGISTER_FINISH_API
    const passkeyApi = '/api/passkey/'
    const router = useRouter()
    const {showModal} = getModal()
	let isFreeFile = (!!sessionStorage.getItem('isFreeFile'))

    const callPasskey = async () => {
        const request = sendAuthenticatedRequest(registerStartApi, router, {
            credentials: "include"
        });

	    const data = await (await request).json();
	    if (data.alert) {
		    await displayToastAlert(data.alert)
	    }
	    else {
		    await createPasskey(data);
	    }
    }

    const checkPasskeyAvailability = () => {
        if (window.PublicKeyCredential && PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable && PublicKeyCredential.isConditionalMediationAvailable) {
            Promise.all([
                PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
                PublicKeyCredential.isConditionalMediationAvailable(),
            ]).then(results => {
                if (results.every(r => r === true)) {
                    user.isPasskeyAvailable = true
                }
            });
        }
    }

    const createPasskey = async (data) => {
        const options = parseCreationOptionsFromJSON(data);
	    const response = await create(options)
		    .catch(error => {
				if (error.message.includes('credentials already registered with the relying party.')) {
					displayToastAlert({
						'type': 'WARNING',
						'title': 'Passkey already registered',
						'message': 'Please use another method to create a different passkey, or remove your existing passkey and try again.'
					})
				}
				else {
					displayToastAlert({
						'type': 'WARNING',
						'title': 'Unable to create passkey',
						'message': 'We were unable to create your passkey. Please try again or create a passkey later.'
					})
				}
			    throw error
		    });

        await sendAuthenticatedRequest(registerFinishApi, router, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
	            credential: response,
	            isFreeFile: isFreeFile
            })
        }).then(res => {
	        if (res.ok) {
		        return res
	        } else {
		        return res.json()
	        }
        }).then(async data => {
	        if (data.alert) {
		        displayToastAlert(data.alert)
	        } else {
		        await getPasskeys().then(() => {
			        showModal('passkeyCreated')
		        })
	        }
        });
    }

    const deletePasskey = async (id) => {
	    const queryParam = `isFreefile=${isFreeFile}`;
	    const url = `${passkeyApi}${id}?${queryParam}`;
        await sendAuthenticatedRequest(url, router, {
            method: "DELETE",
            credentials: "include"
        }).then(res => {
			return res.json()
		}).then(async data => {
	        if (PublicKeyCredential.signalAllAcceptedCredentials) {
		        await PublicKeyCredential.signalAllAcceptedCredentials(data)
	        }
        })

        await getPasskeys()
    }

    const getPasskeys = async () => {
	    hideToastAlert()
        user.passkeys = await sendAuthenticatedRequest(passkeyApi, router, {
            credentials: "include"
        }).then(response => {
            return response.json()
        }).then(data => {
            return data
        }).catch(error => {
            console.log(error)
        })
    }

    const updatePasskey = async (id) => {
        // this will update the passkey name based on cred id
        await sendAuthenticatedRequest(passkeyApi + id, router, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: "?",
            })
        })

        await getPasskeys()
    }

    return {
        callPasskey,
        checkPasskeyAvailability,
        createPasskey,
        deletePasskey,
        getPasskeys,
        updatePasskey,
    }
}


export default passkey
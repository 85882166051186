<template>
  <section class="py-5 bg-gray">
      <div class="container">
          <div class="row">
              <div class="col-12 text-center">
                  <h1>Requested Page Not Found on TaxHawk</h1>
              </div>
          </div>
      </div>
  </section>
  <section class="section-padding">
      <div class="container">
          <div class="d-flex justify-content-center">
              <div>
                  <h2>The requested page could not be found:</h2>
                  <ul>
                      <li>We may have removed the page or changed its web address.</li>
                      <li>The bookmark or link you clicked on might be incorrect.</li>
                      <li>Double-check the web address to make sure it wasn't mistyped.</li>
                  </ul>
                  <p>Use the navigation menus to find more resources and information.</p>
              </div>
          </div>
      </div>
  </section>
</template>
const appName = sessionStorage.getItem('appName')

const matomo = () => {
    let _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl',"auth." + appName + ".com/"]);
    _paq.push(["setDocumentTitle", "auth." + appName + ".com" + document.location.pathname]);
    _paq.push(["setCookieDomain", "*." + appName + ".com"]);
    _paq.push(["setDomains", ["*." + appName + ".com"]]);
    _paq.push(["enableCrossDomainLinking"]);
    _paq.push(['enableLinkTracking']);
    _paq.push(['trackPageView']);
}

export default matomo
const getLoading = () => {
    const showLoading = () => {
        document.getElementById('loadingScreen').classList.remove('d-none')
        scrollTo(0,0)

    }
    const hideLoading = () => {
        document.getElementById('loadingScreen').classList.add('d-none')
    }

    return {
        showLoading,
        hideLoading
    }
}

export default getLoading
<template>
  <Navbar />
  <section class="main">
    <div class="container">
      <div class="col-12 position-relative">
        <div id="mainContentArea">
          <router-view />
        </div>
        <Loading />
      </div>
    </div>
  </section>
  <Footer />
</template>

<script setup>
import './scss/_global.scss'
import {useRoute, useRouter} from "vue-router"
import {onMounted, watch} from "vue"
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import Loading from './components/Loading'
import setAppName from "@/functions/setAppName"
import setAppYear from "@/functions/setAppYear"
import setRedirectUrl from "@/functions/setRedirectUrl"
import redirectToLogin from "@/functions/redirectToLogin"
import getLoading from "@/functions/getLoading"
import setServerType from "@/functions/setServerType"
import faqService from "@/functions/faqService"
import checkBrowserSupport from "@/functions/checkBrowserSupport"
import getUrlParameters from "@/functions/getUrlParameters"
import passkey from "@/functions/passkey"
import setPasskeyFeatureFlag from "@/functions/setPasskeyFeatureFlag";

const {checkPasskeyAvailability} = passkey()
const { showLoading } = getLoading()
const { loadFaqs } = faqService()
setAppName()
setAppYear()
getUrlParameters()
setPasskeyFeatureFlag()
setRedirectUrl()
setServerType()
checkBrowserSupport()
checkPasskeyAvailability()

const router = useRouter()
const route = useRoute()

const linkablePages = [
    '/',
    '/ReclaimAccount',
    '/ForgotUsername',
    '/ForgotPassword',
    '/about',
    '/NewUser',
    '/passkey',
    '/confirmEmail',
]

onMounted(() => {
  if (sessionStorage.getItem('isValidBrowserOSConfig') === 'false') {
    showLoading()
    router.push('/browser-entry-denied' + window.location.search)
  }
})

watch(route, () => {
  if (route.path !== '/' && sessionStorage.getItem('faqs' + sessionStorage.getItem('appYear') + 'Loaded') !== 'true') {
    loadFaqs()
  }
})

if (linkablePages.includes(location.pathname) === false)
{
  redirectToLogin()
}

const goToLogin = () => {
  showLoading()
  router.go('/')
}

window.addEventListener("popstate", goToLogin);
</script>
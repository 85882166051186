import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { createApp } from 'vue'
import VueMatomo from 'vue-matomo'
import App from './App.vue'
import router from './router'
import Vue3Storage from 'vue3-storage'
import matomo from "@/functions/matomo";

let appName = sessionStorage.getItem('appName')
let matomoId

if (appName === 'TaxHawk') {
    matomoId = 2
} else if (appName === 'Express1040') {
    matomoId = 3
} else {
    matomoId = 1
}

createApp(App)
    .use(router)
    .use(Vue3Storage, { namespace: "" })
    .use(VueMatomo, {
        host: 'https://analytics.' + appName + '.com',
        siteId: matomoId,
    })
    .mount('#app')

matomo()
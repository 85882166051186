// Bowser documentation https://github.com/bowser-js/bowser?tab=readme-ov-file
// Full list of available browser configs https://github.com/bowser-js/bowser/blob/master/src/constants.js

import Bowser from "bowser"
import {browserData} from "@/functions/browserData"
// import testUserAgent from "@/data/testUserAgent.json"

const checkBrowserSupport = () => {
	let isValidBrowserOSConfig
	let isValidBrowser
	let isValidInAppBrowser
	let isValidOS

	// let userAgent = testUserAgent["mobile"]["android"]["10"]["opera"]["87"]
	let userAgent = window.navigator.userAgent
	let browserInfo = Bowser.getParser(userAgent)
	let browserParsedData = browserInfo.parsedResult

	// check if the user is not in an invalid in-app browser (facebook, instagram, etc.)
	const inAppBrowserDenialStrings = [
		// "FBAV/",
		// "Instagram",
		"musical_ly_",
		"Bytedance"
	]

	for (const string of inAppBrowserDenialStrings) {
		if (userAgent.includes(string)) {
			isValidInAppBrowser = false
			break
		} else {
			isValidInAppBrowser = true
		}
	}

	// check if the user is using a valid browser version
	const allowedBrowsers = {
		amazon_silk: ">=108",
		chrome: ">=87",
		edge: ">=100",
		firefox: ">=102",
		google_search: ">=317",
		googlebot: ">=0",
		safari: ">=13",
		samsung_internet: ">=21",
		yandex: ">1000",
		mobile: {
			opera: ">=80"
		},
		desktop: {
			opera: ">=106"
		}
	}

	if (process.env.VUE_APP_ENV_NAME.toLowerCase() === "test" || process.env.VUE_APP_ENV_NAME.toLowerCase() === "dev") {
		allowedBrowsers.electron = ">=26.0"
	}

	isValidBrowser = browserInfo.satisfies(allowedBrowsers);
	if (isValidBrowser === undefined) {
		isValidBrowser = true
	}

	// check if the user is using a valid OS version
	let osVersion

	if (!!browserParsedData.os.versionName) {
		osVersion = parseFloat(browserParsedData.os.versionName)
	} else {
		osVersion = parseFloat(browserParsedData.os.version)
	}

	const minimumVersionsMap = new Map()
	const minimumOSVersions = {
		"ios": 14
	}

	for (const [os, version] of Object.entries(minimumOSVersions)) {
		minimumVersionsMap.set(os, version)
	}

	if (!!minimumVersionsMap.get(browserParsedData.os.name.toLowerCase())) {
		isValidOS = osVersion >= minimumVersionsMap.get(browserParsedData.os.name.toLowerCase());
	} else {
		isValidOS = true;
	}

	// check and save all conditions
	isValidBrowserOSConfig = isValidBrowser && isValidOS && isValidInAppBrowser;

	browserData.browser = browserParsedData.browser
	browserData.engine = browserParsedData.engine
	browserData.os = browserParsedData.os
	browserData.platform = browserParsedData.platform
	browserData.userAgent = userAgent

	sessionStorage.setItem("isValidBrowserOSConfig", isValidBrowserOSConfig.toString())
	sessionStorage.setItem("isValidBrowser", isValidBrowser.toString())
	sessionStorage.setItem("isValidOS", isValidOS.toString())
	sessionStorage.setItem("isValidInAppBrowser", isValidInAppBrowser.toString())
}

export default checkBrowserSupport
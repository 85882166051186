const getAppName = () => {
    let appName
    let url = location.href

    if (url.includes('freetaxusa')) {
        appName = 'FreeTaxUSA'
    } else if (url.includes('taxhawk')) {
        appName = 'TaxHawk'
    } else if (url.includes('express1040')) {
        appName = 'Express1040'
    }

    return appName
}

export default getAppName
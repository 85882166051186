import { reactive } from 'vue'
import amendedImage from "@/assets/img/amended-return-icon.svg"
import proSupportImage from "@/assets/img/pro-support-icon.svg"
import auditImage from "@/assets/img/audit-defense-icon.svg"
import deluxeImage from "@/assets/img/deluxe-support-icon.svg"

const getBannerProductsInOrder  = (year) => {
    return [
        {
            "parameter": "AMENDED",
            "message": "You're all set to start your amended tax return",
            "addBackMessage": "No problem, get started to file your " + year + " taxes",
            "deleteLinkText": "Never mind, I don’t need to file an amendment",
            "addLinkText": "Actually, I need to file an amendment",
            "image": amendedImage,
            "oldestActiveYear": (Number(process.env.VUE_APP_CURRENT_TAX_YEAR) - 7)
        },
        {
            "parameter": "PROSUPPORT",
            "message": "Great choice, you’ll be filing with Pro Support!",
            "addBackMessage": "No problem, you’re all set with free federal filing",
            "deleteLinkText": "Never mind, I’ll go without Pro Support",
            "addLinkText": "Actually, I want to have Pro Support",
            "image": proSupportImage,
            "oldestActiveYear": year < 2023 ? 2023 : (Number(process.env.VUE_APP_CURRENT_TAX_YEAR) - 3)
        },
        {
            "parameter": "AD",
            "message": "Great choice, you’ll be filing with Audit Defense!",
            "addBackMessage": "No problem, you’re all set with free federal filing",
            "deleteLinkText": "Never mind, I’ll go without Audit Defense",
            "addLinkText": "Actually, I want to have Audit Defense",
            "image": auditImage,
            "oldestActiveYear": 2023
        },
        {
            "parameter": "DLX",
            "message": "Great choice, you’ll be filing with Deluxe Support!",
            "addBackMessage": "No problem, you’re all set with free federal filing",
            "deleteLinkText": "Never mind, I’ll go without Deluxe Support",
            "addLinkText": "Actually, I want to have Deluxe Support",
            "image": deluxeImage,
            "oldestActiveYear": (Number(process.env.VUE_APP_CURRENT_TAX_YEAR) - 7)
        }
    ]
}

export const productBannerData = reactive({
    "parameter" : "",
    "message" : "",
    "addBackMessage" : "",
    "deleteLinkText" : "",
    "addLinkText" : "",
    "displayBanner" : false,
    "displayDeleteLinkText" : true,
    "image" : ""
})

export const getBannerProducts = () => {
    let year = Number(sessionStorage.getItem('appYear'))
    let parameters = sessionStorage.getItem('urlParamString')
    let activeProducts = []
    let bannerProductsInOrder = getBannerProductsInOrder(year)
    bannerProductsInOrder.forEach(product => {
        if (product.oldestActiveYear <= year && parameters.includes(product.parameter)) {
            activeProducts.push({
                "parameter" : product.parameter,
                "message" : product.message,
                "addBackMessage" : product.addBackMessage,
                "deleteLinkText" : product.deleteLinkText,
                "addLinkText" : product.addLinkText,
                "image" : product.image
            })
        }
    })
    showBanner(activeProducts)
}

export const showBanner = (products) => {
    if (products.length > 0) {
        productBannerData.parameter = products[0].parameter
        productBannerData.message = products[0].message
        productBannerData.addBackMessage = products[0].addBackMessage
        productBannerData.displayBanner = true
        productBannerData.displayDeleteLinkText = true
        productBannerData.image = products[0].image
        productBannerData.deleteLinkText = products[0].deleteLinkText
        productBannerData.addLinkText = products[0].addLinkText
    }
    else {
        productBannerData.displayDeleteLinkText = false
    }
}

export const addProductParameter = (parameter) => {
    let parameters = JSON.parse(sessionStorage.getItem('urlParamString'))
    if (!parameters.includes(parameter)) {
        parameters.push(parameter)
    }
    sessionStorage.setItem('urlParamString', JSON.stringify(parameters))
    getBannerProducts()
}

export const removeProductParameter = (parameter) => {
    let parameters = JSON.parse(sessionStorage.getItem('urlParamString'))
    let index = parameters.indexOf(parameter)
    if (index > -1) {
        parameters.splice(index, 1)
    }
    sessionStorage.setItem('urlParamString', JSON.stringify(parameters))
    getBannerProducts()
}
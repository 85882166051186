<template>
  <div class="modal-backdrop" @click="$emit('close')">
    <div @click.stop class="modal" role="dialog" aria-labelledby="security-modal">

      <div class="modal-content">
        <header class="modal-header" id="security-modal">
          <h1>Your Information is Safe</h1>
          <button id="securityModalCloseBtn" type="button" class="btn-close" @click="$emit('close')" aria-label="Close modal"></button>
        </header>

        <section class="modal-body" id="security-modal-content">
          <div class="row text-center text-sm-start pb-4">
            <div class="col-12 col-sm-4 order-1 order-sm-2 text-center">
              <img style="max-width: 152px" src="../../assets/img/authorized-efile-provider-trans.png" alt="">
            </div>
            <div class="col-12 col-sm-8 order-2 order-sm-1">
              <h2>Trusted Company</h2>
              <p>As an authorized IRS e-file provider, we meet or exceed the security requirements outlined by the IRS.</p>
            </div>
          </div>
          <div class="row text-center text-sm-start pb-4">
            <div class="col-12 col-sm-4 order-1 order-sm-2 text-center">
              <div v-if="appName === 'TaxHawk'">
                <div id="DigiCertClickID_iXDhev8z" data-language="en"></div>
                <component is="script">
                  var __dcid = __dcid || []; __dcid.push({"cid":"DigiCertClickID_iXDhev8z","tag":"iXDhev8z"}); (function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
                </component>
              </div>
              <div v-else-if="appName === 'Express1040'">
                <div id="DigiCertClickID_dQHIjamZ" data-language="en"></div>
                <component is="script">
                  var __dcid = __dcid || []; __dcid.push({"cid":"DigiCertClickID_dQHIjamZ","tag":"dQHIjamZ"}); (function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
                </component>
              </div>
              <div v-else >
                <div id="DigiCertClickID_7EPyCYl-" data-language="en"></div>
                <component is="script">
                  var __dcid = __dcid || []; __dcid.push({"cid":"DigiCertClickID_7EPyCYl-","tag":"7EPyCYl-"}); (function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
                </component>
              </div>
            </div>
            <div class="col-12 col-sm-8 order-2 order-sm-1">
              <h2>We Keep Your Information Private</h2>
              <p>Since we use SSL certificates, your connection is encrypted and sensitive information is safe with us.</p>
            </div>
          </div>
          <div class="row text-center text-sm-start pb-4">
            <div class="col-12 col-sm-4 order-1 order-sm-2 text-center">
              <img src="../../assets/img/sm_ccsafe_whborder.gif" alt="">
            </div>
            <div class="col-12 col-sm-8 order-2 order-sm-1">
              <h2>Your Payment Cards are Secure</h2>
              <p>We comply with rigorous data security standards to protect against theft of your payment information.</p>
            </div>
          </div>
          <div class="row text-center text-sm-start pb-4">
            <div class="col-12 col-sm-4 order-1 order-sm-2 text-center">
              <a href="//privacy.trustarc.com/privacy-seal/validation?rid=c42c3969-4ba9-45a8-a024-d06b6af0032e" target="_blank">
                <img style="border: none" src="//hostedseal.trustarc.com/privacy-seal/seal?rid=c42c3969-4ba9-45a8-a024-d06b6af0032e" alt="TRUSTe"/>
              </a>
            </div>
            <div class="col-12 col-sm-8 order-2 order-sm-1">
              <h2>Privacy is Our Priority</h2>
              <p>Compliance to industry standards ensures adherence to privacy best practices.
                <button type="button" @click="showDifferentModal('security', 'privacy')">Read privacy policy</button>
              </p>
            </div>
          </div>
        </section>
      </div>

      <footer class="modal-footer">
        <button type="button" class="btn btn-white" @click="$emit('close')" aria-label="Close modal">Close</button>
      </footer>

    </div>
  </div>
</template>

<script setup>
import getModal from "@/functions/getModal"

const {showDifferentModal} = getModal()
const appName = sessionStorage.getItem('appName')
</script>

<style lang="scss" scoped>
img {
  max-width: 250px;
  padding-bottom: 1rem;

  @include breakpoint(sm) {
    padding-bottom: 0;
  }
}
.digicert {
  padding: 45px 0 0;

  @include breakpoint(sm) {
    padding: 80px 0 0;
  }
}
</style>
const getModal = () => {

    const showModal = (name) => {
        name += 'Modal'
        const modal = document.getElementById(name)
        let id = name + 'CloseBtn'
        if (modal) {
            modal.classList.remove('modal-backdrop-hidden')
            modal.children[0].classList.remove('modal-hidden')
        }
        if (document.getElementById(id)) {
            document.getElementById(id).focus()
        }
    }
    const closeModal = (name) => {
        name += 'Modal'
	    const modal = document.getElementById(name)
	    modal.classList.add('modal-backdrop-hidden')
	    modal.children[0].classList.add('modal-hidden')
        document.getElementById("firstTabIndex").focus()
    }
    const showDifferentModal = (currentModal, newModal) => {
        closeModal(currentModal)
        showModal(newModal)
    }

    return {
        showModal,
        closeModal,
        showDifferentModal,
    }
}

export default getModal
<template>
  <div class="modal-backdrop" @click.native="$emit('close')">
    <div @click.stop class="modal" role="dialog" aria-labelledby="accessibility-modal">

      <div class="modal-content">
        <header class="modal-header" id="accessibility-modal">
          <h1>Accessibility</h1>
          <button id="accessibilityModalCloseBtn" type="button" class="btn-close" @click="$emit('close')" aria-label="Close modal"></button>
        </header>

        <section class="modal-body text-start" id="accessibility-modal-content">
          <div class="d-flex flex-column flex-md-row">
            <div class="order-2 order-md-1">
              <p>TaxHawk, Inc. is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and applying the relevant accessibility standards. We engage in efforts necessary to meet online usability and web page design requirements recommended by the World Wide Web Consortium (W3C) in its Web Content Accessibility Guidelines 2.1.</p>
            </div>
            <div class="order-1 order-md-1 text-center">
              <img class="p-2" style="width: 7rem;" src="../../assets/img/ncg-logo.png" alt="Accessibility">
            </div>
          </div>
          <h2>Accessibility Standards</h2>
          <p>For the purposes of this policy, TaxHawk, Inc. conforms the Worldwide Web Consortium's Web Content Accessibility Guidelines version 2.1, Level AA Conformance (WCAG 2.1 Level AA).  We use software tools to regularly test and evaluate the accessibility of our website and to make improvements by partnering with National Compliance Group.</p>
          <p>Recognizing the ongoing evolution of current web content and technologies, National Compliance Group will continue to test TaxHawk, Inc. on an ongoing basis for accessibility and report accessibility issues for this website. Any features found to be inaccessible will be addressed in a timely manner.</p>
          <h2>Third Party Sites</h2>
          <p>Throughout this website, we make use of different third-party applications and websites. These applications and websites, which are not controlled by TaxHawk, Inc., may present challenges for individuals with disabilities that we are not able to control or remedy.</p>
          <h2>Accommodation</h2>
          <p>If you encounter any page on our site that presents a challenge for individuals with disabilities, please submit your feedback. TaxHawk, Inc. accepts calls from relay services. You may also request any modifications to our policies, practices and procedures. Please contact us at <a :href="'mailto:support@support.' + appName.toLowerCase() + '.com'">support@support.{{appName.toLowerCase()}}.com</a>.</p>

        </section>
      </div>

      <footer class="modal-footer">
        <button type="button" class="btn btn-white" @click="$emit('close')" aria-label="Close modal">Close</button>
      </footer>

    </div>
  </div>
</template>

<script setup>
const appName = sessionStorage.getItem('appName')
const serverType = sessionStorage.getItem('serverType')
</script>

<style lang="scss" scoped>
img {
  max-width: 250px;
  padding-bottom: 1rem;

  @include breakpoint(sm) {
    padding-bottom: 0;
  }
}
</style>